import Loading from "../../components/loading/loading";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import DiscountIcon from "@mui/icons-material/Discount";
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../components/context/CompanyContext";
import carrinho from "../../icons/carrinhos.svg";
import { userRequest } from "../../requestMethods";
import "./report.css";
import Avatar from "@mui/material/Avatar";
import { generateFileUrl } from "../../requestMethods";
import Box from "@mui/material/Box";

export default function StockReport() {
  const [gridStock, setGridStock] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState();

  const nav = useNavigate();
  const [columns] = useState([
    {
      field: "avatar",
      headerName: "Foto",
      headerClassName: "dataGridMuiHeader",
      align: "center",
      headerAlign: "center",
      width: 50,
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <Avatar src={generateFileUrl(params.value)} />
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status Produto",
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
      description:
        "S = todas numeração tem em estoque, P = alguma numeração não tem em estoque, N = não tem estoque",
      display: "flex",
      renderCell: (params) => {
        // eslint-disable-next-line default-case
        switch (params.value) {
          case "S":
            return (
              <>
                <CheckCircleOutlineOutlinedIcon color="success" />
              </>
            );
          case "N":
            return (
              <>
                <RemoveCircleOutlineOutlinedIcon sx={{ color: "red" }} />
              </>
            );
          case "P":
            return (
              <>
                <ReportGmailerrorredIcon sx={{ color: "#EE8253" }} />
              </>
            );
        }
      },
    },
    {
      field: "product",
      headerName: "Referência",
      headerClassName: "dataGridMuiHeader",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "dataGridMuiHeader",
    },
    {
      field: "promo",
      headerName: "Promocional",
      width: 130,
      align: "center",
      headerAlign: "center",
      type: "boolean",
      headerClassName: "dataGridMuiHeader",
      display: "flex",
      renderCell: (params) => {
        return params.value ? <DiscountIcon color="success" /> : false;
      },
    },
    {
      field: "color",
      headerName: "Cor",
      headerClassName: "dataGridMuiHeader",
      width: 80,
      align: "left",
      display: "flex",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              height: "40px",
              width: "40px",
              backgroundColor: params.value,
              borderRadius: "5px",
            }}
          ></Box>
        );
      },
    },
    {
      field: "category",
      headerName: "Categoria",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "total",
      headerName: "Total",
      headerClassName: "dataGridMuiHeader",
      width: 80,
      align: "left",
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!currentUser) {
      nav(`../login`);
    }

    if (companyId > 0) {
      getCategories();
      getSizes();
      getGridStock();
    }
  }, [companyId]);

  const addColumn = (newColumn) => {
    const colum = columns.find((item) => item.field === newColumn.field);

    if (!colum) {
      columns.push(newColumn);
    }
  };

  const getSizes = async () => {
    try {
      const res = await userRequest.get(`${companyId}/products/sizes`);
      res.data.map((item) =>
        addColumn({
          field: item.ref,
          headerName: item.label,
          headerClassName: "dataGridMuiHeader",
          width: 80,
          align: "left",
        })
      );
    } catch {}
  };

  const getGridStock = async () => {
    setIsLoading(true);
    try {
      const res = await userRequest.get(`${companyId}/grid/stock`);
      setGridStock(res.data);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };
  const getCategories = async () => {
    const res = await userRequest.get(`${companyId}/products/categories/admin`);
    setCategories(res.data);
  };
  const _voltarTopo = () => {
    window.scrollTo(0, 0);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );

  return (
    <div className="div_home_Container">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <table width="100%" className="tableSpaceTab">
            <tbody>
              <tr>
                <div className="div_home_custom">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td align="left" width="100%">
                          <tr>
                            <td>
                              <img src={carrinho} />
                            </td>
                            <td>
                              <tr className="labelTitle">Estoque Completo</tr>
                              <tr className="labelMsgData">
                                Exibindo todos os dados
                              </tr>
                            </td>
                          </tr>
                        </td>
                        <td align="left" width="0%">
                          {" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div style={{ height: 900, width: "100%" }}>
                    {gridStock ? (
                      <DataGridPremium
                        sx={{
                          fontFamily: "Manrope",
                          fontWeight: "500",
                          border: "0px",
                        }}
                        rowHeight={45}
                        localeText={
                          ptBRCore.components.MuiDataGrid.defaultProps
                            .localeText
                        }
                        slots={{ toolbar: CustomToolbar }}
                        slotProps={{
                          panel: {
                            sx: {
                              top: "15px !important",
                              left: "50px !important",
                              position: "fixed !important",
                            },
                          },
                        }}
                        getRowId={(row) =>
                          row.color + row.size + row.stock + row.product
                        }
                        rows={gridStock}
                        disableSelectionOnClick
                        columns={columns}
                        pageSize={8}
                      />
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </tr>
            </tbody>
          </table>
          <div className="divRodape" onClick={_voltarTopo}>
            voltar ao topo
          </div>
        </>
      )}
    </div>
  );
}
